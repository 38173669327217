import React from "react"
import HomepageData from "./data/extendedhomepage.json"
import Styles from "./css/extendedhomepage.module.scss"
import {isChrome,isFirefox,isIeEdge,isSafari, getBrowser} from "@tightrope/lpscripts/browserdetect"

class ExtendedHomepage extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      isIeEdge: isIeEdge(),
      isSafari: isSafari(),
      display: 'none',
      pageData: props.data,
      data: HomepageData
    }
  }

  headline(data){
    let params = new URLSearchParams(window.location.search);
    let hc = params.get("hc");
    let kw = params.get("kw");
    let bn = params.get("bn");
    let qsData = {};
    if(hc){
      qsData.headline = hc;
    }
    if(kw){
      qsData.subhead = kw;
    }
    if(bn){
      qsData.mainCTA = bn;
    }
    if(qsData){
      const queryData = Object.assign(data, qsData);
      this.setState({data: queryData});
    }
  }

  showBrowsers(browsers){
    let display = false;
    if(getBrowser() === 'ie' && browsers.includes('ie')){
      display=true;
    }
   else if(getBrowser() === 'firefox' && browsers.includes('firefox')){
      display=true;
    }
   else if(getBrowser() === 'chrome' && browsers.includes('chrome')){
      display=true;
    }
   else if(getBrowser() === 'edge' && browsers.includes('edge')){
      display=true;
    }
  else if (getBrowser() === "safari" && browsers.includes("safari")) {
          display = true;
    }
   else if(getBrowser() === 'other' && browsers.includes('other')){
      display=true;
    }
    if(display)
      this.setState({display: 'block'});
    else
      this.setState({display: 'none'});
  }

  componentDidMount() {
    this.props.data.then(data => {
      const instanceData = Object.assign(this.state.data, data);
      this.setState({ data: instanceData});
    this.state.isChrome = isChrome();
    this.state.isFirefox = isFirefox();
    this.state.isIeEdge = isIeEdge();
    this.state.isSafari = isSafari();

    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if (isSafari()) {
        const browserData = Object.assign(this.state.data, this.state.data.safari);
        this.setState({ data: browserData });
    }
    if (isIeEdge()) {
        const browserData = Object.assign(this.state.data, this.state.data.edge);
        this.setState({ data: browserData });
    }
        this.headline(this.state.data);
    if(this.props.browsers){
      this.showBrowsers(this.props.browsers);
    }
    else{
      this.setState({display: 'block'});
    }
    });
  }

  render() {
    return (
      <div className = {Styles.extendedhomepage}  style={{ display: this.state.display }} >
      <div className = {Styles.allContainer}>
        <header><img className = {Styles.toplogo} src={this.state.data.logo}/>
      	</header>

      <div className={Styles.banner}>
      		<div className = {Styles.bannerLeft}>
          
      		<h1 className = {Styles.headline}>{this.state.data.productHeadline}</h1>
      			<h2>{this.state.data.subhead}</h2>
      			<button onClick = {() => { if(navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return; else window.triggerInstall()}} data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
      			{this.props.children}
            </div>
            
            </div>     

      <div className = {Styles.middle2}>
      <img alt='Aioli Sous Chef' src={this.state.data.clearSS}/>
      <div>
      <h3>{this.state.data.mid2Headline}</h3>
      <h4>{this.state.data.mid2Sub1}</h4>
      <p>{this.state.data.mid2Line1}</p>
      <h4>{this.state.data.mid2Sub2}</h4>
      <p>{this.state.data.mid2Line2}</p>
      <h4>{this.state.data.mid2Sub3}</h4>
      <p>{this.state.data.mid2Line3}</p>
      </div>

      </div>

      <div className = {Styles.bottom}>
      		<h3 className = {Styles.headlineMid}> {this.state.data.freedom}</h3>
          <div>
      		<div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead1}</h4>
          <p>{this.state.data.freedomPara1}</p>
          </div>
          <div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead2}</h4>
          <p>{this.state.data.freedomPara2}</p>
          </div>
          <div className = {Styles.bottomTile}>
          <h4>{this.state.data.freedomHead3}</h4>
          <p>{this.state.data.freedomPara3}</p>
          </div>
          </div>
      </div>
    
      <div className = {Styles.chunkyFooter}>
        <div>
          <h6>{this.state.data.footerHead}</h6>
          <p>{this.state.data.footerPara}</p>
        
        <button onClick = {() =>  { if (navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1) return; else return window.triggerInstall()} } data-cy="cta" className = {`${Styles.st2} ${Styles.bn_param} ${Styles.cws_dl}`}><span dangerouslySetInnerHTML={{ __html: this.state.data.mainCTA }}></span></button>
        {this.props.children}
        </div>
        <video className={Styles.howToVideo} loop autoPlay muted><source src={this.state.data.clearLaptop}></source></video>
        </div>
      </div>
    </div>
    )
  }
}
export default ExtendedHomepage
